function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import Service from '@ember/service';
import { getOwner } from '@ember/application';
export default class EnsureRegisteredService extends Service {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "classNonces", new WeakMap());
    _defineProperty(this, "nonceCounter", 0);
  }
  register(klass, owner = getOwner(this)) {
    let nonce = this.classNonces.get(klass);
    if (nonce == null) {
      nonce = `-ensure${this.nonceCounter++}`;
      this.classNonces.set(klass, nonce);
      owner.register(`component:${nonce}`, klass);
    }
    return nonce;
  }
}