import { assert } from '@ember/debug';
import { isRecord, isString } from 'ember-this-fallback/types/util';
function isDeprecation(value) {
  if (Array.isArray(value) && value.length === 3) {
    const [message, _test, options] = value;
    return isString(message) && isRecord(options) && isString(options.id);
  } else {
    return false;
  }
}
export function assertIsDeprecation(value) {
  assert('value was expected to be a `deprecate` params array', isDeprecation(value));
  return value;
}
export function assertIsDeprecations(value) {
  assert('value was expected to be am array of `deprecate` params arrays', Array.isArray(value) && value.every(isDeprecation));
  return value;
}