var _dec, _obj;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import { computed } from "@ember/object";
import FormTemplate from "discourse/models/form-template";
import MultiSelectComponent from "select-kit/components/multi-select";
export default MultiSelectComponent.extend((_dec = computed("templates"), (_obj = {
  pluginApiIdentifiers: ["form-template-chooser"],
  classNames: ["form-template-chooser"],
  selectKitOptions: {
    none: "form_template_chooser.select_template"
  },
  init() {
    this._super(...arguments);
    if (!this.templates) {
      this._fetchTemplates();
    }
  },
  didUpdateAttrs() {
    this._super(...arguments);
    this._fetchTemplates();
  },
  get content() {
    if (!this.templates) {
      return this._fetchTemplates();
    }
    return this.templates;
  },
  _fetchTemplates() {
    FormTemplate.findAll().then(result => {
      let sortedTemplates = this._sortTemplatesByName(result);
      if (this.filteredIds) {
        sortedTemplates = sortedTemplates.filter(t => this.filteredIds.includes(t.id));
      }
      if (sortedTemplates.length > 0) {
        return this.set("templates", sortedTemplates);
      } else {
        this.set("templates", sortedTemplates);
        this.set("selectKit.options.disabled", true);
      }
    });
  },
  _sortTemplatesByName(templates) {
    return templates.sort((a, b) => a.name.localeCompare(b.name));
  }
}, (_applyDecoratedDescriptor(_obj, "content", [_dec], Object.getOwnPropertyDescriptor(_obj, "content"), _obj)), _obj)));