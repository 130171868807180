var _class;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import { registerDestructor } from "@ember/destroyable";
import { autoUpdate } from "@floating-ui/dom";
import Modifier from "ember-modifier";
import { bind } from "discourse-common/utils/decorators";
import { updatePosition } from "float-kit/lib/update-position";
let FloatKitApplyFloatingUi = (_class = class FloatKitApplyFloatingUi extends Modifier {
  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, instance => instance.teardown());
  }
  modify(element, [trigger, options, instance]) {
    instance.content = element;
    this.instance = instance;
    this.options = options ?? {};
    if (this.options.autoUpdate) {
      this.cleanup = autoUpdate(trigger, element, this.update);
    } else {
      this.update();
    }
  }
  async update() {
    await updatePosition(this.instance.trigger, this.instance.content, this.options);
  }
  teardown() {
    this.cleanup?.();
  }
}, (_applyDecoratedDescriptor(_class.prototype, "update", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class);
export { FloatKitApplyFloatingUi as default };