import { assert } from '@ember/debug';

/** Asserts that the given value is not undefined. */
export function assertExists(value, message = 'assertExists failed') {
  assert(message, value !== undefined);
  return value;
}
export function isRecord(value) {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}
export function isString(value) {
  return typeof value === 'string';
}