var _obj;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import { action, computed } from "@ember/object";
import { htmlSafe } from "@ember/template";
import I18n from "discourse-i18n";
import DropdownSelectBoxComponent from "select-kit/components/dropdown-select-box";
const UNPINNED = "unpinned";
const PINNED = "pinned";
export default DropdownSelectBoxComponent.extend((_obj = {
  pluginApiIdentifiers: ["pinned-options"],
  classNames: ["pinned-options"],
  selectKitOptions: {
    showCaret: true
  },
  modifySelection(content) {
    const pinnedGlobally = this.get("topic.pinned_globally");
    const pinned = this.value;
    const globally = pinnedGlobally ? "_globally" : "";
    const state = pinned ? `pinned${globally}` : UNPINNED;
    const title = I18n.t(`topic_statuses.${state}.title`);
    content.label = htmlSafe(`<span>${title}</span>`);
    content.title = title;
    content.name = state;
    content.icon = `thumbtack${state === UNPINNED ? " unpinned" : ""}`;
    return content;
  },
  content: computed(function () {
    const globally = this.topic.pinned_globally ? "_globally" : "";
    return [{
      id: PINNED,
      name: I18n.t(`topic_statuses.pinned${globally}.title`),
      description: this.site.mobileView ? null : I18n.t(`topic_statuses.pinned${globally}.help`),
      icon: "thumbtack"
    }, {
      id: UNPINNED,
      name: I18n.t("topic_statuses.unpinned.title"),
      icon: "thumbtack unpinned",
      description: this.site.mobileView ? null : I18n.t("topic_statuses.unpinned.help")
    }];
  }),
  onChange(value) {
    const topic = this.topic;
    if (value === UNPINNED) {
      return topic.clearPin();
    } else {
      return topic.rePin();
    }
  }
}, (_applyDecoratedDescriptor(_obj, "onChange", [action], Object.getOwnPropertyDescriptor(_obj, "onChange"), _obj)), _obj));