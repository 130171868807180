import { helper } from '@ember/component/helper';
import { deprecate } from '@ember/debug';
import { assertIsDeprecations } from 'ember-this-fallback/types/deprecations';
/**
 * Calls @ember/debug `deprecate` for each provided set of `deprecate` params.
 */
const deprecationsHelper = helper(([deprecationsJson]) => {
  const deprecations = assertIsDeprecations(JSON.parse(deprecationsJson));
  for (const deprecation of deprecations) {
    deprecate(...deprecation);
  }
});
export default deprecationsHelper;