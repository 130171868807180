var _class, _class3, _descriptor, _class4;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { registerDestructor } from "@ember/destroyable";
import { cancel } from "@ember/runloop";
import { inject as service } from "@ember/service";
import Modifier from "ember-modifier";
import concatClass from "discourse/helpers/concat-class";
import discourseLater from "discourse-common/lib/later";
import { bind } from "discourse-common/utils/decorators";
const CSS_TRANSITION_DELAY_MS = 300;
const TRANSITION_CLASS = "-fade-out";
let AutoCloseToast = (_class = class AutoCloseToast extends Modifier {
  constructor(owner1, args1) {
    super(owner1, args1);
    _defineProperty(this, "element", void 0);
    _defineProperty(this, "close", void 0);
    _defineProperty(this, "duration", void 0);
    _defineProperty(this, "transitionLaterHandler", void 0);
    _defineProperty(this, "closeLaterHandler", void 0);
    registerDestructor(this, instance1 => instance1.cleanup());
  }
  modify(element1, _1, {
    close: close1,
    duration: duration1
  }) {
    this.element = element1;
    this.close = close1;
    this.duration = duration1;
    this.element.addEventListener("mouseenter", this.stopTimer, {
      passive: true
    });
    this.element.addEventListener("mouseleave", this.startTimer, {
      passive: true
    });
    this.startTimer();
  }
  startTimer() {
    this.transitionLaterHandler = discourseLater(() => {
      this.element.classList.add(TRANSITION_CLASS);
      this.closeLaterHandler = discourseLater(() => {
        this.close();
      }, CSS_TRANSITION_DELAY_MS);
    }, this.duration);
  }
  stopTimer() {
    cancel(this.transitionLaterHandler);
    cancel(this.closeLaterHandler);
  }
  cleanup() {
    this.stopTimer();
    this.element.removeEventListener("mouseenter", this.stopTimer);
    this.element.removeEventListener("mouseleave", this.startTimer);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "startTimer", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "startTimer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopTimer", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "stopTimer"), _class.prototype)), _class);
let DToasts = (_class3 = (_class4 = class DToasts extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "toasts", _descriptor, this);
  }
}, template(`
    <section class="fk-d-toasts">
      {{#each this.toasts.activeToasts as |toast|}}
        <output
          role={{if toast.options.autoClose "status" "log"}}
          key={{toast.id}}
          class={{concatClass "fk-d-toast" toast.options.class}}
          {{(if
            toast.options.autoClose
            (modifier
              AutoCloseToast close=toast.close duration=toast.options.duration
            )
          )}}
        >
          <toast.options.component
            @data={{toast.options.data}}
            @close={{toast.close}}
          />
        </output>
      {{/each}}
    </section>
  `, {
  component: _class4,
  eval() {
    return eval(arguments[0]);
  }
}), _class4), (_descriptor = _applyDecoratedDescriptor(_class3.prototype, "toasts", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class3);
export { DToasts as default };