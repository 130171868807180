export const POPULAR_THEMES = [{
  name: "Air",
  value: "https://github.com/discourse/discourse-air",
  preview: "https://discourse.theme-creator.io/theme/awesomerobot/air-theme",
  description: "A clean and modern theme for Discourse.",
  meta_url: "https://meta.discourse.org/t/discourse-air-theme/197703"
}, {
  name: "Graceful",
  value: "https://github.com/discourse/graceful",
  preview: "https://discourse.theme-creator.io/theme/awesomerobot/graceful",
  description: "A light and graceful theme for Discourse.",
  meta_url: "https://meta.discourse.org/t/a-graceful-theme-for-discourse/93040"
}, {
  name: "Material Design Theme",
  value: "https://github.com/discourse/material-design-stock-theme",
  preview: "https://discourse.theme-creator.io/theme/tshenry/material-design",
  description: "Inspired by Material Design, this theme comes with several color palettes.",
  meta_url: "https://meta.discourse.org/t/material-design-stock-theme/47142"
}, {
  name: "Mint",
  value: "https://github.com/discourse/discourse-mint-theme",
  preview: "https://discourse.theme-creator.io/theme/awesomerobot/mint",
  description: "A minty colored theme with featured homepage categories and a search banner.",
  meta_url: "https://meta.discourse.org/t/mint-a-modern-theme-for-discourse/202822"
}, {
  name: "Sam's Simple Theme",
  value: "https://github.com/discourse/discourse-simple-theme",
  preview: "https://discourse.theme-creator.io/theme/sam/simple",
  description: "Simplified front page design with classic colors and typography.",
  meta_url: "https://meta.discourse.org/t/sams-personal-minimal-topic-list-design/23552"
}, {
  name: "Brand Header",
  value: "https://github.com/discourse/discourse-brand-header",
  preview: "https://discourse.theme-creator.io/theme/vinothkannans/brand-header",
  description: "Add an extra top header with your logo, navigation links and social icons.",
  meta_url: "https://meta.discourse.org/t/brand-header-theme-component/77977",
  component: true
}, {
  name: "Custom Header Links",
  value: "https://github.com/discourse/discourse-custom-header-links",
  preview: "https://discourse.theme-creator.io/theme/awesomerobot/custom-header-links",
  description: "Easily add custom text-based links to the header.",
  meta_url: "https://meta.discourse.org/t/custom-header-links/90588",
  component: true
}, {
  name: "GIFs Search",
  value: "https://github.com/discourse/discourse-gifs",
  description: "Adds a button to easily search and insert GIFs in posts.",
  meta_url: "https://meta.discourse.org/t/discourse-gifs-component/158738",
  component: true
}, {
  name: "Category Banners",
  value: "https://github.com/discourse/discourse-category-banners",
  preview: "https://discourse.theme-creator.io/theme/awesomerobot/discourse-category-banners",
  description: "Show banners on category pages using your existing category details.",
  meta_url: "https://meta.discourse.org/t/discourse-category-banners/86241",
  component: true
}, {
  name: "Tag Banners",
  value: "https://github.com/discourse/discourse-tag-banners",
  preview: "https://discourse.theme-creator.io/theme/awesomerobot/tag-banners",
  description: "Show banners on tag pages using your existing tag details.",
  meta_url: "https://meta.discourse.org/t/tag-banners/124240",
  component: true
}, {
  name: "Kanban Board",
  value: "https://github.com/discourse/discourse-kanban-theme",
  preview: "https://discourse.theme-creator.io/theme/david/kanban",
  description: "Display and organize topics using a Kanban board interface.",
  meta_url: "https://meta.discourse.org/t/kanban-board-theme-component/118164",
  component: true
}, {
  name: "Sidebar Theme Toggle",
  value: "https://github.com/discourse/discourse-sidebar-theme-toggle",
  description: "Displays a theme selector in the sidebar menu’s footer provided there is more than one user-selectable theme.",
  meta_url: "https://meta.discourse.org/t/sidebar-theme-toggle/242802",
  component: true
}, {
  name: "Header Submenus",
  value: "https://github.com/discourse/discourse-header-submenus",
  preview: "https://discourse.theme-creator.io/theme/awesomerobot/header-submenus",
  description: "Lets you build a header menu with submenus (dropdowns).",
  meta_url: "https://meta.discourse.org/t/header-submenus/94584",
  component: true
}, {
  name: "Automatic Table of Contents",
  value: "https://github.com/discourse/DiscoTOC",
  description: "Generates an interactive table of contents on the sidebar of your topic with a simple click in the composer.",
  meta_url: "https://meta.discourse.org/t/discotoc-automatic-table-of-contents/111143",
  component: true
}, {
  name: "Easy Responsive Footer",
  value: "https://github.com/discourse/Discourse-easy-footer",
  preview: "https://discourse.theme-creator.io/theme/Johani/easy-footer",
  description: "Add a fully responsive footer without writing any HTML.",
  meta_url: "https://meta.discourse.org/t/easy-responsive-footer/95818",
  component: true
}];