var _class;
import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { isTesting } from "discourse-common/config/environment";
export default class DFloatPortal extends Component {
  get inline() {
    return this.args.inline ?? isTesting();
  }
}
_class = DFloatPortal;
template(`
    {{#if this.inline}}
      {{yield}}
    {{else}}
      {{#in-element @portalOutletElement}}
        {{yield}}
      {{/in-element}}
    {{/if}}
  `, {
  component: _class,
  eval() {
    return eval(arguments[0]);
  }
});