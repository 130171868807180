import { template } from "@ember/template-compiler";
const DButtonTooltip = template(`
  <div class="fk-d-button-tooltip" ...attributes>
    {{yield to="button"}}
    {{yield to="tooltip"}}
  </div>
`, {
  eval() {
    return eval(arguments[0]);
  }
});
export default DButtonTooltip;