import Helper from '@ember/component/helper';
import { getOwner } from './get-owner';
import { assertExists } from './types/util';
/**
 * Returns the helper `Factory` for the helper with the provided name if it
 * exists and `undefined` if not.
 * Similar to `helper` helper, but avoids build-time errors for
 * this-fallback invocations.
 */
export default class TryLookupHelper extends Helper {
  compute([name]) {
    const owner = assertExists(getOwner(this), 'Could not find owner');
    return owner.factoryFor(`helper:${name}`)?.class;
  }
}